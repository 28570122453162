import { Component, Input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: 'InfoCard',
  standalone: true,
  imports: [TranslateModule],
  template: `
    <div class="bg-white dark:bg-gray-600 p-4 mt-6 rounded-2xl shadow-dropdown cursor-pointer" (click)="hintExpanded = !hintExpanded">
      <div class="flex flex-row">
        <div class="font-medium text-[15px] dark:text-white flex-grow" [translate]="titleKey"></div>
        <div class="p-0.5">
          <!-- chevron -->
          <svg [class.rotate-180]="hintExpanded" class="fill-current h-6 w-6 mt-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
          </svg>
        </div>
      </div>
      <div class="text-[13px] text-gray-600 dark:text-gray-200 mt-1 overflow-hidden" [class.h-5]="!hintExpanded" [innerHTML]="bodyKey | translate"></div>
    </div>
  `,
})
export class InfoCard {
  @Input() titleKey: string;
  @Input() bodyKey: string;
  hintExpanded = false;
}
