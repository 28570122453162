import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "../services/user/user.service";

@Injectable({
  providedIn: 'root'
})
export class CheckLoginGuard {

  constructor(private userService: UserService, private router: Router) {
  }

  canActivate() {
     return this.userService.isLoggedIn() ? true : this.router.navigateByUrl('');
  }

}
