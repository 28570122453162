import { HttpClient } from "@angular/common/http";
import { LocalStorageKey } from "../../local-storage/local-storage-keys";
import { Injectable } from "@angular/core";
import { BaseHttpService } from "../base-http.service";
import { UserService } from "../user/user.service";
import { CombiPackage, UserAPI, UserCombiPackage } from "../../model";
import { LocalStorageService } from "../../local-storage/local-storage.service";
import { MessageService } from "../../modules/shared/message/message.service";
import { ProgressBarService } from "../../modules/shared/progress-bar/progress-bar.service";
import { TranslateService } from "@ngx-translate/core";
import { AdminUserCombiPackage } from "../../model/adminusercombipackage";

@Injectable({
  providedIn: 'root'
})
export class UserCombiPackageService extends BaseHttpService {

  private userAPI: UserAPI;

  private userCombiPackage: UserCombiPackage;

  private combiPackage: CombiPackage;

  private email: string;

  private packageParams: string;

  constructor(protected http: HttpClient,
              protected userService: UserService,
              protected translateService: TranslateService,
              protected progressBarService: ProgressBarService,
              private localStorageService: LocalStorageService,
              protected messageService: MessageService) {
    super(http, userService, translateService, progressBarService, messageService);
  }

  async getUserCombiPackages(): Promise<Array<UserCombiPackage>> {
    return (await this.get<Array<UserCombiPackage>>("authenticated/usercombipackages/list")).result;
  }

  async getAdminUserCombiPackages(): Promise<Array<AdminUserCombiPackage>> {
    return (await this.get<Array<AdminUserCombiPackage>>("authenticated/admin/usercombipackages/list")).result;
  }

  async loadUserCombiPackage(userCombiPackageID: string): Promise<UserCombiPackage> {
    this.userCombiPackage = (await this.get<UserCombiPackage>(`authenticated/usercombipackage/${userCombiPackageID}`)).result;
    return this.userCombiPackage;
  }

  async uninstall(userCombiPackageID: string, uninstallReason?: string, uninstallExperience?: string): Promise<Array<UserCombiPackage>> {
    return (await this.post<Array<UserCombiPackage>>(
        `authenticated/usercombipackage/uninstall`,
        {userCombiPackageID, uninstallReason, uninstallExperience}))
        .result;
  }

  async toggleActive(userCombiPackageID: string): Promise<Array<UserCombiPackage>> {
    return (await this.post<Array<UserCombiPackage>>(`authenticated/usercombipackage/toggle/${userCombiPackageID}`)).result;
  }

  getUserAPI(): UserAPI {
    if (!this.userAPI) {
      this.userAPI = this.localStorageService.get<UserAPI>(LocalStorageKey.CURRENT_USERAPI);
      this.localStorageService.remove(LocalStorageKey.CURRENT_USERAPI);
    }
    return this.userAPI;
  }

  setUserAPI(userApi: UserAPI): void {
    this.userAPI = userApi;
    this.localStorageService.set(LocalStorageKey.CURRENT_USERAPI, userApi);
  }

  setUserCombiPackage(userCombiPackage: UserCombiPackage): void {
    this.userCombiPackage = userCombiPackage;
    this.localStorageService.set(LocalStorageKey.CP_USERCOMBIPACKAGE, userCombiPackage);
  }

  getUserCombiPackage(): UserCombiPackage {
    if (!this.userCombiPackage) {
      this.userCombiPackage = this.localStorageService.get(LocalStorageKey.CP_USERCOMBIPACKAGE);
    }
    return this.userCombiPackage;
  }

  setCombiPackage(combiPackage: CombiPackage): void {
    this.combiPackage = combiPackage;
    this.localStorageService.set(LocalStorageKey.CP_COMBIPACKAGE, combiPackage);
  }

  getCombiPackage(): CombiPackage {
    if (!this.combiPackage) {
      this.combiPackage = this.localStorageService.get(LocalStorageKey.CP_COMBIPACKAGE);
    }
    return this.combiPackage;
  }

  getEmail(): string {
    if (!this.email) {
      this.email = this.localStorageService.get(LocalStorageKey.CP_EMAIL);
    }
    return this.email;
  }

  setEmail(email: string) {
    this.email = email;
    this.localStorageService.set(LocalStorageKey.CP_EMAIL, email);
  }

  getPackageParams(): string {
    if (!this.packageParams) {
      this.packageParams = this.localStorageService.get(LocalStorageKey.CP_PACKAGEPARAMS);
    }
    return this.packageParams;
  }

  setPackageParams(value: string) {
    if (value) {
      this.packageParams = value;
      this.localStorageService.set(LocalStorageKey.CP_PACKAGEPARAMS, value);
    }
  }

  clearState() {
    this.localStorageService.remove(LocalStorageKey.CP_EMAIL);
    this.localStorageService.remove(LocalStorageKey.CP_COMBIPACKAGE);
    this.localStorageService.remove(LocalStorageKey.CP_PACKAGEPARAMS);
    this.localStorageService.remove(LocalStorageKey.CP_USERCOMBIPACKAGE);
    this.localStorageService.remove(LocalStorageKey.CURRENT_USERAPI);
  }
}
