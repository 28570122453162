import { Component, output, Input, OnInit } from '@angular/core';
import { AppCard } from './AppCard';
import { AppFilter } from './AppFilter';
import { AppSuggestion } from './AppSuggestion';
import { Button } from './Button';
import { Wrap } from './Wrap';
import { CategoryService } from "../services/category/category.service";
import { ApiService } from "../services/api/api.service";
import { API, Category } from "../model";
import { logViewApiList } from "../util/analytics.util";
import { ActivatedRoute } from "@angular/router";
import { SharedModule } from "../modules/shared/shared.module";

import { ModalService } from "../modules/shared/modal/modal.service";
import { MissingYoursModalComponent } from "../pages/apps/missing-yours/missing-yours-modal.component";
import { Meta, Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { SUPPORTED_CONTENT_CATEGORIES } from "../pages/apps/apps.component";

@Component({
  selector: 'Apps',
  standalone: true,
  imports: [SharedModule, AppCard, AppFilter, AppSuggestion, Button, Wrap],
  template: `
    <section
        class="
      pb-15 lg:pb-20 relative my-section
      {{ background && 'bg-gradient-to-b from-white to-blue-50' }}
      "
    >
      <Wrap [class]="'md:-mt-5'">
        <h2 class="text-xl md:text-3xl mb-4 md:mb-8">
          {{ (title ? title : 'APPS_TITLE') | translate }}
        </h2>

        @if (loaded) {
          <AppFilter
              [categoryId]="categoryId"
              [categories]="categories"
              (updateCategory)="updateCategory($event)"
              (updateQuery)="updateQuery($event)"
          ></AppFilter>
        }

        @if (loaded && apis) {
          <div
              class="grid grid-cols-2 xs:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-5 md:gap-6"
              (window:resize)="setAppsAmount()"
          >
            @for (app of getApps(); track app.id) {
              <a
                  class="my-button text-center group transform hover:scale-105 transition-transform"
                  href="/integrations/{{ escapeParentheses(app.name) }}/{{ app.internalKey }}"
              >
                <AppCard [name]="app.name" [logo]="app.nameNormalized + '.svg'"></AppCard>

                <h3 class="text-base mb-0 md:mb-2 group-hover:text-blue-500 transition-colors">
                  {{ app.name }}
                </h3>
              </a>
            }

            @if (showApps <= 0 || getFilteredApps().length < showApps + 1) {
              <span
                  class="text-center group cursor-pointer"
                  (click)="showMissingYoursModal()"

              >
            <AppSuggestion></AppSuggestion>

            <h3 class="text-base group-hover:text-blue-500 transition-colors mb-0">
              {{ 'APPS_MISSING_TITLE' | translate }}
            </h3>

            <p class="font-mark-heavy font-extrabold text-blue-500 mb-0 md:mb-2 underline decoration-2 underline-offset-4 leading-snug">
              {{ 'APPS_MISSING_CTA' | translate }}
            </p>
          </span>
            }
          </div>
        }

        <app-loading></app-loading>
      </Wrap>

      @if (apis && !showAll && showApps > 0 && getFilteredApps().length > showApps) {
        <div
            class="absolute w-full flex justify-center bottom-0 transform translate-y-1/2"

        >
          <Button color="blue" (click)="moreApps()">+ {{ 'APPS_BUTTON_MORE' | translate }}</Button>
        </div>
      }
    </section>
  `,
})
export class Apps implements OnInit {
  @Input() background = true;
  @Input() categoryId = '';
  @Input() showAll = false;
  @Input() title?: string;
  categoryChanged = output<string>();

  loaded = false;

  apis: Array<API>;
  categories: Array<Category>;

  showApps = 6;
  query = '';

  constructor(private categoryService: CategoryService,
              public route: ActivatedRoute,
              private titleService: Title,
              private metaService: Meta,
              private translate: TranslateService,
              private apiService: ApiService,
              private modalService: ModalService<MissingYoursModalComponent>) {
  }

  async ngOnInit() {
    this.setAppsAmount();
    this.apiService.getApis().then(apis => this.apis = apis);

    const category = this.route.snapshot.params["category"];
    if (category) {
      await this.loadCategories();
      this.loaded = true;
      const selectedCategory = this.categories.filter(cat => cat.name.toLowerCase() === category)[0];
      if (selectedCategory) {
        this.categoryId = "" + selectedCategory.id;

        const pageTitle = this.translate.instant("BREADCRUMBS_INTEGRATIONS") + " > " + selectedCategory.name;
        this.titleService.setTitle(pageTitle);
        this.metaService.updateTag({
          name: "description",
          content: pageTitle
        });
      }
    } else {
      // noinspection ES6MissingAwait (no need to await this)
      this.loadCategories();
      this.loaded = true;
    }
  }

  // TODO this gets triggered too often while loading the page
  getApps() {
    const apps = this.getFilteredApps();

    return this.showApps > 0 ? apps.slice(0, this.showApps) : apps;
  }

  getFilteredApps() {
    let apps = this.apis;

    if (this.query) {
      apps = apps.filter(
          (app) => app.name.toLowerCase().indexOf(this.query.toLowerCase()) !== -1
      );
    }

    if (this.categoryId) {
      apps = apps.filter((app) => app.categories.indexOf(+this.categoryId) > -1);
    }

    return apps;
  }

  moreApps() {
    this.showApps = -1;
  }

  setAppsAmount() {
    if (this.showAll) {
      this.showApps = 9999;
    } else if (this.showApps > 0) {
      if (window.innerWidth >= 1280) {
        this.showApps = 12;
      } else if (window.innerWidth >= 1024) {
        this.showApps = 10;
      } else if (window.innerWidth >= 768) {
        this.showApps = 8;
      } else {
        this.showApps = 6;
      }
    }
  }

  updateCategory(categoryId: string) {
    this.categoryId = categoryId;
    if (categoryId) {
      const category = this.categories.filter(cat => cat.id === +categoryId)[0];
      this.categoryChanged.emit(SUPPORTED_CONTENT_CATEGORIES[category.name.toLowerCase()]);
      logViewApiList(category.internalKey, category.name);
    }
  }

  updateQuery(value: string) {
    this.query = value;
  }

  escapeParentheses(what: string): string {
    return what
      .replace("(", '%28')
      .replace(")", '%29');
  }

  async showMissingYoursModal() {
    const modalService: ModalService<MissingYoursModalComponent> = this.modalService.create(MissingYoursModalComponent);
    await modalService.open();
  }

  private async loadCategories() {
    this.categories = await this.categoryService.getCategories();
  }
}
