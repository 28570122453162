import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {

  public percentageLoaded = 0;

  shouldShow(): boolean {
    return this.percentageLoaded > 0;
  }
}
