import { Component, OnInit } from "@angular/core";
import { AbstractModalComponent } from "../../../modules/shared/modal/abstract-modal.component";
import { UserService } from "../../../services/user/user.service";
import { MissingYoursService } from "../../../services/missing-yours/missing-yours.service";
import { logAnalyticsEvent } from "../../../util/analytics.util";
import { TranslateModule } from "@ngx-translate/core";
import { Button } from "../../../components";
import { SharedModule } from "../../../modules/shared/shared.module";
import { API } from "../../../model";
import { ApiService } from "../../../services/api/api.service";

@Component({
  selector: 'app-missing-yours-modal',
  templateUrl: './missing-yours-modal.component.html',
  standalone: true,
  imports: [Button, SharedModule, TranslateModule]
})
export class MissingYoursModalComponent extends AbstractModalComponent implements OnInit {
  name: string;
  email: string;
  remarks: string;
  newsletter = true;

  appFrom: string;
  appTo: string;

  apis: Array<API>;

  constructor(userService: UserService,
              private missingYoursService: MissingYoursService,
              private apiService: ApiService) {
    super();
    this.email = userService.getUser()?.email;
    this.name = userService.getUser()?.name;
  }

  async ngOnInit() {
    this.apis = await this.apiService.getApis();
  }

  async submitForm() {
    if (await this.missingYoursService.saveApiVote(this.name, this.email, this.remarks, this.newsletter, this.appFrom, this.appTo)) {
      // note sure the item_name supports a long remark, but let's give it a shot, details: https://developers.google.com/analytics/devguides/collection/ga4/reference/events#add_to_wishlist
      logAnalyticsEvent("add_to_wishlist", {items: [{item_name: this.remarks}]});
      this.close();
    }
  }
}
