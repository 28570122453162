import { Component, Input } from '@angular/core';

@Component({
  selector: 'AppCard',
  standalone: true,
  template: `
    <div
      class="aspect-square rounded-3xl bg-white shadow-soft mb-2.5 flex items-center justify-center"
    >
      <img
        [alt]="name"
        class="w-1/2 h-1/2"
        src="assets/images/apps/{{ logo }}"
        height="90"
        width="90"
      />
    </div>
  `,
})
export class AppCard {
  @Input() logo = '';
  @Input() name = '';
}
