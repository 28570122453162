import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { initializeApp, FirebaseOptions } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";

if (environment.production) {
  enableProdMode();
}

// only enable firebase features for prod
if (environment.env === "prod") {
  const firebaseConfig: FirebaseOptions = {
    apiKey: "AIzaSyBMtqtKd0XkuDKO4AN9f-7eCBnRT3bIfyo",
    authDomain: "astute-synapse-95314.firebaseapp.com",
    projectId: "astute-synapse-95314",
    storageBucket: "astute-synapse-95314.appspot.com",
    messagingSenderId: "543477738215",
    appId: "1:543477738215:web:a2f5aea9844cfb47ac9042",
    measurementId: "G-HDH8M6YM6Y"
  };
  const firebaseApp = initializeApp(firebaseConfig);
  getAnalytics(firebaseApp);
  getPerformance(firebaseApp);
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
