import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'Button',
  standalone: true,
  imports: [CommonModule],
  template: `
    @if (href) {
      <a
          href="{{ href }}"
          target="{{ target }}"
          rel="{{ rel }}"
          class="{{ styles.join(' ') }}"
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </a>
    } @else {

      @if (!submit) {
        <div class="{{ styles.join(' ') }}">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
      } @else {
        <button type="submit" [disabled]="disabled" class="{{ styles.join(' ') }}">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </button>
      }
    }

    <ng-template #submit>
      <button type="submit" [disabled]="disabled" class="{{ styles.join(' ') }}">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </button>
    </ng-template>

    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
  `,
})
export class Button implements OnInit {
  @Input() class = '';
  @Input() submit = false;
  @Input() disabled = false;
  @Input() target = "_self";
  @Input() color: 'purple' | 'blue' | 'green' | 'red' | 'white' | 'yellow' | 'gray' | 'dropdown' = 'purple';
  @Input() href = '';
  @Input() rel = 'opener';
  @Input() outline = false;
  @Input() shadow = true;
  @Input() size: 'xsmall' | 'small' | 'medium' = 'medium';

  styles: string[] = [
    'my-button flex items-center rounded-full font-mark-bold font-medium text-center transition-colors duration-200',
  ];

  ngOnInit() {
    this.styles.push(this.class);

    // only allow wrapping on "dropdown" (and "green" (selected)) buttons
    switch (this.color) {
      case 'dropdown':
      case 'green':
        break;
      default:
        this.styles.push(`whitespace-nowrap`);
        break;
    }

    // Define button style based on outline and color props
    if (this.outline) {
      this.styles.push(this.size === 'xsmall' ? 'border' : 'border-2');

      switch (this.color) {
        case 'blue':
          this.styles.push(`
            border-blue-500 text-blue-500
            hover:border-blue-600 hover:bg-blue-600 hover:text-white
          `);
          break;
        case 'green':
          this.styles.push(`
              border-green-500 text-green-500
              hover:border-green-600 hover:bg-green-600 hover:text-white
            `);
          break;
        case 'red':
          this.styles.push(`
            border-red-500 text-red-500
            hover:border-red-600 hover:bg-red-600 hover:text-white
          `);
          break;
        case 'white':
          this.styles.push(`
            border-white text-white
            hover:bg-white hover:text-purple-600
          `);
          break;
        case 'yellow':
          this.styles.push(`
            border-yellow-500 text-yellow-500
            hover:border-yellow-600 hover:bg-yellow-600 hover:text-white
          `);
          break;
        case 'gray':
          this.styles.push(`border-gray-400 text-gray-500`);
          if (!this.disabled) { // TODO can be applied to other colors as well
            this.styles.push(`hover:border-gray-800 hover:bg-gray-800 hover:text-white`);
          }
          break;
        default:
          this.styles.push(`
            border-purple-500 text-purple-500
            hover:border-purple-600 hover:bg-purple-600 hover:text-white
          `);
          break;
      }
    } else {
      if (this.shadow) {
        this.styles.push('shadow-lg');
      }

      switch (this.color) {
        case 'blue':
          this.styles.push(`
            text-white border-2
            bg-blue-500 border-blue-500 shadow-blue-500/50
            hover:bg-blue-600 hover:border-blue-600
          `);
          break;
        case 'green':
          this.styles.push(`
            text-white border-2
            bg-green-500 border-green-500 shadow-green-500/50
            hover:bg-green-600 hover:border-green-600
          `);
          break;
        case 'red':
          this.styles.push(`
            text-white border-2
            bg-red-500 border-red-500 shadow-red-500/50
            hover:bg-red-600 hover:border-red-600 hover:text-white
          `);
          break;
        case 'white':
          this.styles.push(`
            text-white border-2
            bg-white border-white text-purple-600 shadow-purple-500/50
            hover:bg-purple-500 hover:border-purple-500 hover:text-white
          `);
          break;
        case 'yellow':
          this.styles.push(`
            text-white border-2
            bg-yellow-500 border-yellow-500 shadow-yellow-500/50
            hover:bg-yellow-600 hover:border-yellow-600
          `);
          break;
        case 'dropdown':
          this.styles.push(`
            bg-dropdown dark:bg-dropdown-dark shadow-dropdown text-gray-800 dark:text-white
          `);
          break;
        case 'gray':
          this.styles.push(`
            text-white border-2
            bg-gray-500 border-gray-500 shadow-gray-500/50
            hover:bg-gray-600 hover:border-gray-600
          `);
          break;
        default:
          this.styles.push(`
            text-white border-2
            bg-purple-500 border-purple-500 shadow-purple-500/40
            hover:bg-purple-600 hover:border-purple-600
          `);
          break;
      }
    }

    // Define button size based on size prop
    switch (this.size) {
      case 'xsmall':
        this.styles.push('px-3 py-1.5 text-sm rounded-xl');
        break;
      case 'small':
        this.styles.push('px-6 py-3 text-sm');
        break;
      case 'medium':
      default:
        this.styles.push('px-6 py-3 text-sm md:px-8 md:py-4 md:text-sm');
        break;
    }
  }
}
