import { Injectable } from "@angular/core";
import { BaseHttpService } from "../base-http.service";
import { UserService } from "../user/user.service";
import { HttpClient } from "@angular/common/http";
import { MessageService } from "../../modules/shared/message/message.service";
import { ProgressBarService } from "../../modules/shared/progress-bar/progress-bar.service";
import { TranslateService } from "@ngx-translate/core";
import { Branch } from "./branch.dto";

@Injectable({
  providedIn: 'root'
})
export class ActiveBranchService extends BaseHttpService {

  constructor(protected http: HttpClient,
              protected userService: UserService,
              protected translateService: TranslateService,
              protected progressBarService: ProgressBarService,
              protected messageService: MessageService) {
    super(http, userService, translateService, progressBarService, messageService);
  }

  async listBranches(): Promise<Array<Branch>> {
    return (await this.get<Array<Branch>>(`internalonly/activebranch/branches`)).result;
  }

  async activateBranch(branchName: string): Promise<void> {
    await this.post<void>(`internalonly/activebranch/activate/${branchName}`);
  }
}
