import { ActivatedRoute } from "@angular/router";

const NOT_VISIBLE_EXCEPTIONS = [
  'magento2',
  'ccvshop',
  '-orderchamp',
  '/external/',
  '/oauthshopifyrequest/',
  '/oauthshopifysdkrequest/',
  '/shopifycombipackage/',
  '/shopifydashboard/',
  '/shopifypaymentconfirmation/',
  '/shopifysdkpaymentconfirmation',
  '/oauthresult',
  '/shopifyoauthresult',
  '/bizcuit'
];

export const headerFooterVisible = (activatedRoute: ActivatedRoute): boolean =>
    !NOT_VISIBLE_EXCEPTIONS.some(e => activatedRoute.toString().includes(e));
