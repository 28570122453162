import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from "@angular/core";
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { SharedModule } from "./modules/shared/shared.module";
import { AppRoutingModule } from './routing/app-routing.module';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { Observable } from "rxjs";
import { OAuthResultComponent } from './pages/oauth-result/oauth-result.component';
import { AutoLoginComponent } from "./pages/auto-login/auto-login.component";
import { ShopifyOAuthResultComponent } from "./pages/shopify-oauth-result/shopify-oauth-result.component";
import { EcwidOauthResultComponent } from "./pages/ecwid-oauth-result/ecwid-oauth-result.component";
import { ScriptLoaderService } from "angular-google-charts";
import { MessageModule } from "./modules/shared/message/message.module";

class LowercaseTranslateHttpLoader extends TranslateHttpLoader {
  override getTranslation(lang: string): Observable<object> {
    // I saw an uppercase NL file being requested, which doesn't work.. so lowercasing here
    return super.getTranslation(lang.toLowerCase());
  }
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    const interpolateParams: any = params.interpolateParams;
    if (interpolateParams?.fallbackText) {
      return interpolateParams.fallbackText;
    } else if (interpolateParams?.fallbackTranslation) {
      return params.translateService.get(interpolateParams.fallbackTranslation, interpolateParams);
    } else if (interpolateParams?.fallbackTranslationValue) {
      return interpolateParams?.fallbackTranslationValue;
    }
  }
}

// AoT requires an exported function for factories
export const HttpLoaderFactory = (http: HttpClient) => new LowercaseTranslateHttpLoader(http);

@NgModule({
  declarations: [
    AppComponent,
    AutoLoginComponent,
    OAuthResultComponent,
    EcwidOauthResultComponent,
    ShopifyOAuthResultComponent
  ],
  imports: [
    SharedModule,
    MessageModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      defaultLanguage: "en",
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler
      }
    })
  ],
  providers: [
    Title,
    ScriptLoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
