import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user/user.service';
import { Menu } from './Menu';
import { Wrap } from './Wrap';
import { User } from "../model";
import { LocalStorageKey } from "../local-storage/local-storage-keys";
import { LocalStorageService } from "../local-storage/local-storage.service";
import { redirect } from "../util/redirect.util";

@Component({
  selector: 'AdminUserHeaderNotice',
  standalone: true,
  imports: [Menu, Wrap],
  template: `
    @if (adminUser) {
      <div class="bg-yellow-bright-500 text-gray-800 hidden sm:flex justify-between items-center text-sm">
        <div class="px-5 py-4">
          Je bent ingelogd als client <span class="font-bold">{{ user.email }}</span>.
          <span class="underline cursor-pointer" (click)="returnToAdminAccount()">Klik hier</span> om terug te gaan naar
          je eigen account.
        </div>
      </div>
    }
  `,
})
export class AdminUserHeaderNotice implements OnInit {
  user: User;
  adminUser: User;

  constructor(private userService: UserService,
              private localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {
    this.adminUser = this.userService.getAdminUser();
    if (this.adminUser) {
      this.user = this.userService.getUser();
    }
  }

  returnToAdminAccount(): void {
    this.userService.clearLocalStorage();
    this.localStorageService.remove(LocalStorageKey.ADMIN_USER);
    redirect(null, window.location.origin + "/autologin/" + this.adminUser.encryptedID + "?target=admin/clients");
  }
}
