import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector, Type } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ModalService<T> {
  private componentRef: ComponentRef<T> | undefined;
  private resultPromiseResolve: any;

  constructor(
      private componentFactoryResolver: ComponentFactoryResolver,
      private appRef: ApplicationRef,
      private injector: Injector) {
  }

  get component(): T {
    return this.componentRef.instance;
  }

  create(component: Type<T>): ModalService<T> {
    if (this.componentRef) {
      return undefined;
    }

    this.componentRef = this.componentFactoryResolver
        .resolveComponentFactory<T>(component)
        .create(this.injector);

    return this;
  }

  open(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (!this.componentRef) {
        return;
      }

      // added a little delay to avoid ghostclicks
      setTimeout(() => {
        this.appRef.attachView(this.componentRef.hostView);

        const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);
        this.resultPromiseResolve = resolve;
      }, 200);
    });
  }

  async close(result?: any): Promise<void> {
    if (!this.componentRef) {
      return;
    }

    this.appRef.detachView(this.componentRef.hostView);
    this.componentRef.destroy();

    this.componentRef = undefined;
    this.resultPromiseResolve(result);
  }
}
