import { Component, OnInit } from "@angular/core";
import { Message } from "./message";
import { MessageService } from "./message.service";

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html'
})
export class MessageComponent implements OnInit {
  message: Message;

  constructor(private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.message = this.messageService.message;
  }

  hideMessage(): void {
    this.messageService.hideMessage();
  }
}
