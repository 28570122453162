import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ConfirmModalComponent } from "./confirm-modal/confirm-modal.component";
import { TranslateModule } from "@ngx-translate/core";
import { LoadingComponent } from './loading/loading.component';
import { ModalComponent } from './modal/modal.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { EmailComponent } from "./email/email.component";
import { NewsletterComponent } from "./newsletter/newsletter.component";
import { Breadcrumbs, Button, Cta, Footer, Header, HeaderTitle, Hero, Main, MoreLink, Wrap } from "../../components";
import { AutofocusDirectiveModule } from "./autofocus/autofocus.module";
import { MessageModalComponent } from "./message-modal/message-modal.component";

@NgModule({
  declarations: [
    LoadingComponent,
    ModalComponent,
    DropdownComponent,
    ConfirmModalComponent,
    MessageModalComponent,
    EmailComponent,
    NewsletterComponent
  ],
  imports: [
    AutofocusDirectiveModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    HttpClientModule,
    RouterModule,
    Breadcrumbs,
    Button,
    Cta,
    Footer,
    Header,
    HeaderTitle,
    Hero,
    Main,
    MoreLink,
    Wrap
  ],
  exports: [
    AutofocusDirectiveModule,
    FormsModule,
    TranslateModule,
    LoadingComponent,
    ModalComponent,
    RouterModule,
    DropdownComponent,
    ConfirmModalComponent,
    EmailComponent,
    NewsletterComponent,
    Breadcrumbs,
    Button,
    Cta,
    Footer,
    Header,
    HeaderTitle,
    Hero,
    Main,
    MoreLink,
    Wrap
  ]
})
export class SharedModule {
}
