import { Component } from '@angular/core';
import { Wrap } from './Wrap';
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: 'Logos',
  standalone: true,
  imports: [TranslateModule, Wrap],
  template: `
    <section class="my-section">
      <Wrap>
        <h2 class="h1 md:text-[42px] text-center mb-8 md:mb-12 lg:mb-16">
          {{ 'LOGOS_TITLE_1' | translate }}
          <span class="block text-yellow-500 sm:inline">
            {{ 'LOGOS_TITLE_2' | translate }}
          </span>
          {{ 'LOGOS_TITLE_3' | translate }}
        </h2>

        <div class="shadow-soft-xl rounded-3xl md:rounded-4xl p-5">
          <div class="overflow-hidden">
            <div
                class="grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-4 md:grid-cols-5 -m-px"
            >
              @for (customer of customers; track customer.name) {
                <div class="
                    flex
                    justify-center
                    p-5
                    border
                    border-gray-100
                    xs:[&:nth-child(10)]:hidden
                    sm:[&:nth-child(9)]:hidden
                    md:[&:nth-child(9)]:flex
                    md:[&:nth-child(10)]:flex">
                  <img
                      [alt]="customer.name"
                      class="w-[100px] md:w-[150px] opacity-50 grayscale"
                      src="assets/images/logos/{{ customer.logo }}.png"
                      srcset="assets/images/logos/{{ customer.logo }}@2x.png 2x"
                      height="100"
                      width="150"
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </Wrap>
    </section>
  `,
})
export class Logos {
  customers = [
    {
      name: 'Heineken',
      logo: 'heineken',
    },
    {
      name: 'Canon',
      logo: 'canon',
    },
    {
      name: 'Mazars',
      logo: 'mazars',
    },
    {
      name: 'Keolis',
      logo: 'keolis',
    },
    {
      name: 'Kadoos.nl',
      logo: 'kadoos',
    },
    {
      name: 'ICTRecht',
      logo: 'ictrecht',
    },
    {
      name: 'bijRobert',
      logo: 'bijrobert',
    },
    {
      name: 'Q',
      logo: 'q',
    },
    {
      name: 'Flynth',
      logo: 'flynth',
    },
    {
      name: 'Payt',
      logo: 'payt',
    },
  ];
}
