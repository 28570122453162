import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { UserService } from "../../services/user/user.service";
import { logLogin, setAnalyticsUserId } from "../../util/analytics.util";
import { getUrlParamValue } from "../../util/url.util";

@Component({
  selector: './app-auto-login',
  templateUrl: './auto-login.component.html'
})
export class AutoLoginComponent implements OnInit, OnDestroy {

  private paramsSubscription: Subscription;

  constructor(private userService: UserService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    this.paramsSubscription = this.route.params.subscribe(params => {
      const token = params["token"];
      if (token) {
        const target = getUrlParamValue(window.location.href, "target") || "dashboard";
        const isAdmin = getUrlParamValue(window.location.href, "admin") === "true";
        // something like https://dev.combidesk.com/autologin/c77229ec8ce6baf0a525acdf93cd015d?target=dashboard/history/3
        if (isAdmin) {
          this.doAutoLoginAdmin(token, decodeURIComponent(target));
        } else {
          this.doAutoLogin(token, decodeURIComponent(target));
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }

  private async doAutoLogin(token: string, target: string) {
    if (await this.userService.autoLogin(token)) {
      const user = this.userService.getUser();
      setAnalyticsUserId("" + user.id);
      user.id = undefined;
      this.userService.setUser(user);
      logLogin("auto");
      this.router.navigate(["/" + target]);
    }
  }

  private async doAutoLoginAdmin(token: string, target: string) {
    if (await this.userService.autoLoginAdmin(token)) {
      setAnalyticsUserId(null);
      this.router.navigate(["/" + target]);
    }
  }
}
