import { Component } from "@angular/core";
import { AbstractModalComponent } from "../modal/abstract-modal.component";
import { SharedModule } from "../shared.module";

@Component({
  selector: 'app-popup-blocked-modal',
  templateUrl: './popup-blocked-modal.component.html',
  standalone: true,
  imports: [SharedModule]
})
export class PopupBlockedModalComponent extends AbstractModalComponent {
  href: string;
  target: "_blank" | "_top";
}
