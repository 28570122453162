import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

export const SUPPORTED_CONTENT_CATEGORIES = {
  webwinkel: "webwinkel",
  "e-commerce": "webwinkel",
  boekhouden: "boekhouden",
  bookkeeping: "boekhouden",
  crm: "crm",
  kassa: "kassa",
  "point-of-sale": "kassa",
  betalingen: "betalingen",
  payments: "betalingen"
};


@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html'
})
export class AppsComponent implements OnInit {

  contentCategory: string;

  constructor(private route: ActivatedRoute) {
  }

  async ngOnInit(): Promise<void> {
    this.contentCategory = SUPPORTED_CONTENT_CATEGORIES[this.route.snapshot.params["category"]];
  }
}
