import { Arrow } from './Arrow';
import { AfterViewInit, Component } from '@angular/core';
import { Logo } from './Logo';
import { SocialIcons } from './SocialIcons';
import { Stars } from './Stars';
import { Wrap } from './Wrap';
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";
import { headerFooterVisible } from "../util/header-footer.util";
import { sleep } from "../util/sleep.util";
import { firstValueFrom } from "rxjs";

@Component({
  selector: 'Footer',
  standalone: true,
  imports: [TranslateModule, Arrow, Logo, Stars, SocialIcons, Wrap],
  template: `
    @if (visible()) {
      <div
          class="text-sm bg-gradient-to-tr from-purple-700 to-purple-500 text-white pt-10 pb-8 md:pt-15"
      >
        <Wrap>
          <div
              class="flex justify-between items-start gap-10 w-full mb-10 lg:mb-15"
          >
            <div class="sm:max-w-[240px]">
              <div class="mb-5">
                <Logo></Logo>
              </div>

              <p class="mb-6" translate="SUPERFOOTER_ABOUT_COMBIDESK"></p>

              <SocialIcons></SocialIcons>
            </div>

            <div class="hidden lg:block">
              <h5 class="text-white mt-1 mb-3">{{ title1 }}</h5>
              <div class="flex flex-col items-start">
                @for (item of menu1; track item.href) {
                  <a
                      [href]="item.href"
                      class="hover:text-blue-400 py-1.5"
                  >
                    {{ item.label }}
                  </a>
                }
              </div>
            </div>

            <!--div class="hidden sm:block">
              <h5 class="text-white mt-1 mb-3">{{ title2 }}</h5>
              <div class="flex flex-col items-start">
                <a
                  *ngFor="let item of menu2"
                  [href]="item.href"
                  class="hover:text-blue-400 py-1.5"
                >
                  {{ item.label }}
                </a>
              </div>
            </div-->

            <div class="hidden sm:block">
              <h5 class="text-white mt-1 mb-3">{{ title3 }}</h5>
              <div class="flex flex-col items-start">
                @for (item of menu3; track item.href) {
                  <a
                      [href]="item.href"
                      class="hover:text-blue-400 py-1.5"
                  >
                    {{ item.label }}
                  </a>
                }
              </div>
            </div>

            <div class="hidden sm:block">
              <h5 class="text-white mt-1 mb-3">{{ title4 }}</h5>
              <div class="flex flex-col items-start">
                @for (item of menu4; track item.href) {
                  <a
                      [href]="item.href"
                      class="hover:text-blue-400 py-1.5"
                  >
                    {{ item.label }}
                  </a>
                }
              </div>
            </div>
          </div>

          <div class="
            border-t
            border-b
            border-white/30
            py-5
            font-mark-bold font-bold
            flex
            flex-col
            items-center
            text-center
            mb-5
            md:flex-row
            md:py-6
            md:mb-8
          "
          >
            <div
                class="max-w-[250px] mb-4 md:max-w-none md:mb-0 md:mr-4 lg:text-lg"
                translate="SUPERFOOTER_RATING">
            </div>

            <div class="flex gap-0.5 mb-4 md:mb-0">
              <Stars [rating]="9"></Stars>
            </div>

            <a
                [href]="'SUPERFOOTER_LINK_CUSTOMERSTORIES_URL' | translate"
                class="flex items-center gap-1.5 md:ml-auto hover:text-blue-400"
                translate="SUPERFOOTER_FOOTERLINK_CUSTOMERSTORIES"
            >
              <div class="mt-px">
                <Arrow></Arrow>
              </div>
            </a>
          </div>

          <div
              class="text-sm text-center flex flex-col items-center md:flex-row md:items-center md:text-sm"
          >
            <p class="mb-4 md:mb-0 opacity-75">
              &copy; copyright 2014-{{ year }} - Combidesk B.V.
            </p>
            <div class="md:ml-auto">
              <a class="opacity-75 hover:opacity-100" href="/terms">
                {{ 'SUPERFOOTER_LINK_TERMS' | translate }}
              </a>
              <span class="opacity-75 mx-1 md:mx-2">-</span>
              <a class="opacity-75 hover:opacity-100" href="/privacy">
                {{ 'SUPERFOOTER_LINK_PRIVACY' | translate }}
              </a>
            </div>
          </div>
        </Wrap>
      </div>
    }
  `
})
export class Footer implements AfterViewInit {
  title1: string;
  menu1: { href: string; label: string }[];

  title3: string;
  menu3: { href: string; label: string }[];

  title4: string;
  menu4: { href: string; label: string }[];

  year: number;

  constructor(private activatedRoute: ActivatedRoute,
              private translate: TranslateService) {
  }

  async ngAfterViewInit() {
    // no rush, so delaying a bit to give other things priority
    await sleep(100);
    this.year = new Date().getFullYear();
    this.loadMenuItems(); // no need to await this
    this.translate.onLangChange.subscribe(this.loadMenuItems.bind(this));
  }

  visible(): boolean {
    return headerFooterVisible(this.activatedRoute);
  }

  private async loadMenuItems() {
    this.title1 = await firstValueFrom(this.translate.get('SUPERFOOTER_HEADER_SOFTWAREINTEGRATIONS'));

    this.menu1 = [
      {
        label: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_SOFTWAREINTEGRATIONS_ALL')),
        href: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_SOFTWAREINTEGRATIONS_ALL_URL'))
      },
      {
        label: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_SOFTWAREINTEGRATIONS_BOOKKEEPING')),
        href: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_SOFTWAREINTEGRATIONS_BOOKKEEPING_URL'))
      },
      {
        label: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_SOFTWAREINTEGRATIONS_ECOMMERCE')),
        href: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_SOFTWAREINTEGRATIONS_ECOMMERCE_URL'))
      },
      {
        label: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_SOFTWAREINTEGRATIONS_CRM')),
        href: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_SOFTWAREINTEGRATIONS_CRM_URL'))
      },
      {
        label: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_SOFTWAREINTEGRATIONS_EMAILMARKETING')),
        href: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_SOFTWAREINTEGRATIONS_EMAILMARKETING_URL'))
      },
      {
        label: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_SOFTWAREINTEGRATIONS_PAYMENTS')),
        href: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_SOFTWAREINTEGRATIONS_PAYMENTS_URL'))
      },
      {
        label: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_SOFTWAREINTEGRATIONS_POS')),
        href: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_SOFTWAREINTEGRATIONS_POS_URL'))
      }
    ];

    this.title3 = await firstValueFrom(this.translate.get('SUPERFOOTER_HEADER_ABOUT'));

    this.menu3 = [
      {
        label: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_ABOUT_COMBIDESK')),
        href: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_ABOUT_COMBIDESK_URL'))
      },
      {
        label: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_CUSTOMERSTORIES')),
        href: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_CUSTOMERSTORIES_URL'))
      },
      {
        label: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_ABOUT_US')),
        href: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_ABOUT_US_URL'))
      },
      {
        label: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_BLOG')),
        href: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_BLOG_NEW_URL'))
      },
      {
        label: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_PARTNER')),
        href: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_PARTNER_URL'))
      }
    ];

    this.title4 = await firstValueFrom(this.translate.get('SUPERFOOTER_HEADER_SUPPORT'));

    this.menu4 = [
      {
        label: "Support",
        href: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_CUSTOMERSERVICE_URL'))
      },
      {
        label: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_KNOWLEDGEBASE')),
        href: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_KNOWLEDGEBASE_URL'))
      },
      {
        label: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_INSTALLATION')),
        href: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_INSTALLATION_URL'))
      },
      {
        label: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_ADVISORS')),
        href: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_ADVISORS_URL'))
      },
      {
        label: "Contact",
        href: await firstValueFrom(this.translate.get('SUPERFOOTER_LINK_CONTACT_URL'))
      }
    ];
  }
}
