<app-modal #modal>
  <h3 [translate]="'MODAL_POPUP_BLOCKED_TITLE'"></h3>

  <div class="mt-3">
    <p [translate]="'MODAL_POPUP_BLOCKED_MESSAGE'"></p>
  </div>

  <div class="py-3 flex justify-end">
    <Button [href]="href" (click)="closeDelayed()" [target]="target" color="blue" [shadow]="false" size="small" type="submit">{{ 'MODAL_POPUP_BLOCKED_BUTTON_CONFIRM' | translate }}</Button>
  </div>
</app-modal>
