import { Component } from '@angular/core';
import { Wrap } from './Wrap';

@Component({
  selector: 'Main',
  standalone: true,
  imports: [Wrap],
  template: `
    <main class="my-section">
<!--      <Wrap [class]="'!max-w-[780px]'">-->
      <Wrap>
        <ng-content></ng-content>
      </Wrap>
    </main>
  `,
})
export class Main {}
