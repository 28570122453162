import { Component, Input, OnInit } from '@angular/core';
import { Wrap } from './Wrap';

@Component({
  selector: 'Breadcrumbs',
  standalone: true,
  imports: [Wrap],
  template: `
    <section class="hidden md:block">
      <Wrap
          [class]="
          'border-b border-gray-300 text-gray-400 py-6 leading-tight flex flex-wrap gap-2'
        "
      >
        @for (item of items; track item.href) {

          @if (item.href) {
            <a
                [href]="item.href"
                class="font-bold flex flex-nowrap items-center hover:text-blue-500"
            >
              {{ item.label }}

              <svg
                  class="shrink-0 ml-2"
                  fill="none"
                  height="16"
                  stroke-width="3"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  width="16"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </a>
          }

          @if (!item.href) {
            <span>{{ item.label }}</span>
          }

        }
      </Wrap>
    </section>
  `,
})
export class Breadcrumbs implements OnInit {
  @Input() items: { label: string; href?: string }[] = [];

  ngOnInit() {
    // Always add a link to the homepage
    this.items.unshift({
      label: 'Combidesk',
      href: '/',
    });
  }
}
