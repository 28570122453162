<Header>
  @if (!contentCategory) {
    <HeaderTitle>{{ 'PAGE_APPS_TITLE' | translate }}</HeaderTitle>
  } @else {
    <Hero
        [alt]="'IMG_ALT_PRICE' | translate"
        [illustration]="{
        name: 'apps-header-'+contentCategory,
        height: 403,
        width: 420
      }">
      <h1 class="text-white">{{ 'PAGE_APPS_HERO_TITLE_' + contentCategory | translate }}</h1>
      <p> {{ 'PAGE_APPS_HERO_BODY_' + contentCategory | translate }}</p>

      <div class="flex flex-wrap justify-center lg:justify-start gap-3 mt-10">
        <Button href="/koppelingen" color="red" [shadow]="false">
          {{ 'PAGE_APPS_HERO_BUTTON_TRIAL' | translate }}
        </Button>
        <!--Button href="#informatie-aanvragen" color="white" [outline]="true">
          {{ 'PAGE_HOME_HERO_BUTTON_INFO' | translate }}
        </Button-->
      </div>
    </Hero>
  }
</Header>

@if (!contentCategory) {
  <Breadcrumbs
      [items]="[
        {
          label: 'BREADCRUMBS_INTEGRATIONS' | translate
        }
      ]"
  ></Breadcrumbs>
} @else {
  <Breadcrumbs
      [items]="[
        {
          label: 'BREADCRUMBS_INTEGRATIONS' | translate, href: 'APPS_LINK_ALL' | translate
        },
        {
          label: 'BREADCRUMBS_INTEGRATIONS_' + contentCategory | translate
        }
      ]"
  ></Breadcrumbs>
}

<Apps [background]="false" [showAll]="true" (categoryChanged)="contentCategory = $event"></Apps>

@if (contentCategory) {
  <Intro
      [illustration]="{
      name: 'apps-definition-'+contentCategory,
      height: 336,
      width: 480
    }">
    <h1>{{ 'PAGE_APPS_DEFINITION_TITLE_' + contentCategory | translate }}</h1>
    <p>{{ 'PAGE_APPS_DEFINITION_BODY_' + contentCategory | translate }}</p>
    <p>{{ 'PAGE_APPS_DEFINITION_BODY2_' + contentCategory | translate }}</p>
  </Intro>
}

<Logos></Logos>

@if (contentCategory) {

  <ContentIllustration
      [alt]="'IMG_ALT_BENEFITS' | translate"
      [illustration]="{
        name: 'apps-part1-'+contentCategory,
        height: 336,
        width: 480
      }">
    <h2>{{ 'PAGE_APPS_CONTENT_PART_1_TITLE_' + contentCategory | translate }}</h2>
    <p>{{ 'PAGE_APPS_CONTENT_PART_1_BODY_' + contentCategory | translate }}</p>
  </ContentIllustration>

  <ContentIllustration
      [alt]="'IMG_ALT_INSTALLATION' | translate"
      [illustration]="{
        name: 'apps-part2-'+contentCategory,
        height: 357,
        width: 480
      }"
      [inverse]="true">
    <h2>{{ 'PAGE_APPS_CONTENT_PART_2_TITLE_' + contentCategory | translate }}</h2>
    <p>{{ 'PAGE_APPS_CONTENT_PART_2_BODY_' + contentCategory | translate }}</p>
  </ContentIllustration>

  <ContentIllustration
      [alt]="'IMG_ALT_SECURITY' | translate"
      [illustration]="{
        name: 'apps-part3-'+contentCategory,
        height: 334,
        width: 420
      }">
    <h2>{{ 'PAGE_APPS_CONTENT_PART_3_TITLE_' + contentCategory | translate }}</h2>
    <p>{{ 'PAGE_APPS_CONTENT_PART_3_BODY_' + contentCategory | translate }}</p>
  </ContentIllustration>

  <ContentIllustration
      [alt]="'IMG_ALT_PRICE' | translate"
      [illustration]="{
        name: 'apps-part4-'+contentCategory,
        height: 403,
        width: 420
      }"
      [inverse]="true">
    <h2>{{ 'PAGE_APPS_CONTENT_PART_4_TITLE_' + contentCategory | translate }}</h2>
    <p>{{ 'PAGE_APPS_CONTENT_PART_4_BODY_' + contentCategory | translate }}</p>
  </ContentIllustration>

}

<CustomerReviews></CustomerReviews>

<CustomerService></CustomerService>

<Cta type="support"></Cta>

<Footer></Footer>
