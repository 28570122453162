import { Component, Input } from '@angular/core';

@Component({
  selector: 'MoreLink',
  standalone: true,

  template: `
    <a
      href="{{ href }}"
      class="
      my-button inline-block font-mark-bold font-bold text-blue-500 hover:text-blue-600 pb-1 border-b-2 border-blue-500 hover:border-blue-600
      {{ class }}
      "
    >
      <ng-content></ng-content>
    </a>
  `,
})
export class MoreLink {
  @Input() class = '';
  @Input() href = '';
}
