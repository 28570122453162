import { Router } from "@angular/router";

export const redirect = (router?: Router, url: string = ""): void => {
  if (url.indexOf("http") === 0) {
    // external, and bust out of an iframe in case we're in one
    if (window.top && inIframe()) {
      window.top.location.href = url;
    } else {
      window.location.href = url;
    }
  } else {
    // internal, so use router
    router.navigateByUrl(url, {});
  }
};

const inIframe = (): boolean => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

