import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MessageComponent } from "./message.component";
import { ErrorModalComponent } from "./error-modal/error-modal.component";
import { SharedModule } from "../shared.module";
import { BizcuitButton } from "../../../components/bizcuit/BizcuitButton";
import { InfoCard } from "../../../components/InfoCard";

@NgModule({
  declarations: [
    MessageComponent,
    ErrorModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    BizcuitButton,
    InfoCard
  ],
  exports: [
    MessageComponent,
    ErrorModalComponent
  ]
})
export class MessageModule {
}
