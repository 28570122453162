import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  localStorage: Storage;

  constructor() {
    try {
      this.localStorage = window.localStorage;
    } catch (ignore) {
      console.log("LS not supported");
    }
  }

  get<T>(key: string, fallback = null): T {
    if (this.isLocalStorageSupported()) {
      const value = this.localStorage.getItem(key);
      if (!value || value === "undefined") {
        return fallback;
      }
      return value.startsWith("{") || value.startsWith("[") ? JSON.parse(value) : value;
    }
    return null;
  }

  set(key: string, value: any): boolean {
    if (this.isLocalStorageSupported()) {
      this.localStorage.setItem(key, value && typeof value !== "string" ? JSON.stringify(value) : value);
      return true;
    }
    return false;
  }

  remove(key: string): boolean {
    if (this.isLocalStorageSupported()) {
      this.localStorage.removeItem(key);
      return true;
    }
    return false;
  }

  isLocalStorageSupported(): boolean {
    return !!this.localStorage;
  }
}
