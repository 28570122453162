import { Component } from '@angular/core';
import { Menu } from './Menu';
import { Wrap } from './Wrap';
import { ProgressBarService } from "../modules/shared/progress-bar/progress-bar.service";
import { ActivatedRoute } from "@angular/router";
import { headerFooterVisible } from "../util/header-footer.util";
import { AdminUserHeaderNotice } from "./AdminUserHeaderNotice";

@Component({
  selector: 'Header',
  standalone: true,
  imports: [AdminUserHeaderNotice, Menu, Wrap],
  template: `
    <AdminUserHeaderNotice></AdminUserHeaderNotice>

    <div class="fixed w-full h-1 top-0 z-50">
      @if (pbService.shouldShow()) {
        <div class="overflow-hidden h-1 mb-4 text-xs flex bg-blue-400">
          <div [style]="'transition: all .5s ease; width: ' + pbService.percentageLoaded + '%'"
               class="bg-blue-600"></div>
        </div>
      }
    </div>

    @if (visible()) {
      <div class="bg-gradient-to-tr from-purple-700 to-blue-500 text-white">
        <Menu></Menu>
        <ng-content></ng-content>
      </div>
    }
  `,
})
export class Header {
  pbService: ProgressBarService;

  constructor(private progressBarService: ProgressBarService,
              private activatedRoute: ActivatedRoute) {
    this.pbService = this.progressBarService;
  }

  visible(): boolean {
    return headerFooterVisible(this.activatedRoute);
  }
}
