import { Component } from "@angular/core";
import { ModalService } from "./modal.service";

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent<T> {
  display = true;

  constructor(private modalService: ModalService<T>) {
  }

  async close(result?: any): Promise<void> {
    this.display = false;
    await this.modalService.close(result);
  }
}
