<app-modal #modal>
  <div translate="MODAL_API_VOTE_NOT_AVAILABLE" class="mb-3 text-gray-800"></div>

  <form>
    <div class="relative w-full mb-3 pb-2">
      <input
          class="form-input border"
          [class.border-combidesk-red]="name !== undefined && name?.trim().length === 0"
          [class.border-combidesk-green]="name?.trim().length > 0"
          id="name"
          name="name"
          type="text"
          [attr.placeholder]="'PAGE_ACCOUNTSETTINGS_LABEL_NAME' | translate"
          [(ngModel)]="name"/>
    </div>
    <div class="relative w-full mb-3">
      <app-email [email]="email" (emailChange)="email = $event" [required]="true" [showLabel]="false"></app-email>
    </div>
    <app-newsletter [newsletter]="newsletter" (newsletterChange)="newsletter = $event"></app-newsletter>

    <div translate="MODAL_API_VOTE_CHOOSE_PACKAGE" class="pt-5 pb-2 text-gray-800"></div>
    <div class="flex flex-col items-center gap-3 sm:flex-row sm:gap-5 pb-2">
      <select
          id="appFrom"
          class="w-full"
          name="appFrom"
          [(ngModel)]="appFrom"
      >
        <option value="" translate="INSTALLATION_SUPPORT_FORM_LABEL_CHOOSE"></option>
        @for (api of apis; track api.id) {
          <option [selected]="appFrom === api.name">{{ api.name }}</option>
        }
      </select>
      <svg
          class="w-[20px] h-[12px] md:w-[25px] md:h-[15px] flex-none transform rotate-90 sm:rotate-0"
          viewBox="0 0 50 30"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M17 3 5 15l12 12M5 15h40M33 3l12 12-12 12"
            stroke="#2096F3"
            stroke-width="6"
            fill="none"
            stroke-miterlimit="10"
        />
      </svg>
      <select
          id="appTo"
          class="w-full"
          name="appTo"
          [(ngModel)]="appTo"
      >
        <option value="" translate="INSTALLATION_SUPPORT_FORM_LABEL_CHOOSE"></option>
        @for (api of apis; track api.id) {
          <option [selected]="appTo === api.name">{{ api.name }}</option>
        }
      </select>
    </div>

    <div class="relative w-full mb-3 pt-3">
      <textarea
          class="form-input border"
          [class.border-combidesk-red]="remarks !== undefined && remarks?.trim().length === 0"
          [class.border-combidesk-green]="remarks?.trim().length > 0"
          id="inputRemarks"
          name="inputRemarks"
          maxlength="2000"
          rows="5"
          [(ngModel)]="remarks"
          [attr.placeholder]="'MODAL_API_VOTE_PLACEHOLDER_REQUEST' | translate"></textarea>
    </div>

    <div class="py-3 mt-5 flex justify-end">
      <Button (click)="close()" color="gray" size="small" [outline]="true" [shadow]="false" class="mr-3">{{ 'BUTTON_CANCEL' | translate }}</Button>
      <Button (click)="submitForm()" color="blue" size="small" type="submit">{{ 'MODAL_API_VOTE_BUTTON_SEND' | translate }}</Button>
    </div>
  </form>
</app-modal>
