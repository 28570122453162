import { Component } from "@angular/core";
import { ProgressBarService } from "../progress-bar/progress-bar.service";

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
  pbService: ProgressBarService;

  constructor(private progressBarService: ProgressBarService) {
    this.pbService = progressBarService;
  }
}
