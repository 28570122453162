@if (message.active) {
  <div [class.opacity-0]="message.hide" [class.opacity-95]="!message.hide"
       class="transition-opacity duration-700 bg-white fixed z-50 top-32 right-5 -mr-4 sm:mr-0 flex max-w-sm w-full mx-auto shadow-md rounded-lg overflow-hidden">
    <div
        [class.bg-combidesk-green]="message.type === 'SUCCESS'"
        [class.bg-combidesk-blue]="message.type === 'INFO'"
        [class.bg-combidesk-yellow]="message.type === 'WARN'"
        [class.bg-combidesk-red]="message.type === 'ERROR'"
        class="flex justify-center items-center w-12">
      <svg class="h-6 w-6 fill-current text-white" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        @if (message.type === 'SUCCESS') {
          <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z"/>

        } @else if (message.type === 'INFO') {
          <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z"/>

        } @else if (message.type === 'WARN') {
          <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z"/>

        } @else if (message.type === 'ERROR') {
          <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z"/>
        }
      </svg>
    </div>

    <div class="-mx-3 py-2 px-4">
      <div class="mx-3">
      <span
          [class.text-combidesk-green]="message.type === 'SUCCESS'"
          [class.text-combidesk-blue]="message.type === 'INFO'"
          [class.text-combidesk-yellow]="message.type === 'WARN'"
          [class.text-combidesk-red]="message.type === 'ERROR'"
          class="font-semibold">{{ ('MESSAGE.TYPE.' + message.type) | translate }}</span>
        <p class="text-gray-600 text-sm" style="overflow-wrap: anywhere" [innerHTML]="message.message"></p>
      </div>
    </div>

    @if (message.type === 'WARN' || message.type === 'ERROR') {
      <button
          class="animate-pulse absolute bg-transparent text-lg leading-none right-0 top-0 py-1.5 px-2.5 outline-none focus:outline-none"
          (click)="hideMessage()">
        <span>×</span>
      </button>
    }
  </div>
}