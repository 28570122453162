import { Component, OnInit } from "@angular/core";
import { ErrorMessage } from "./error-message";
import { MessageService } from "../message.service";
import { LocalStorageService } from "../../../../local-storage/local-storage.service";

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html'
})
export class ErrorModalComponent implements OnInit {
  message: ErrorMessage;
  appColor: string;

  constructor(private messageService: MessageService,
              private localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {
    this.appColor = this.localStorageService.get("app_color");
    this.message = this.messageService.errorMessage;
  }

  hideMessage(): void {
    this.messageService.hideErrorMessage();
  }

  buttonClicked() {
    if (this.message.errorObject.buttonAction === "CLOSE") {
      this.hideMessage();
    } else {
      this.messageService.actionButtonClicked(this.message.errorObject.buttonAction);
    }
  }
}
