import { ModalService } from "../modules/shared/modal/modal.service";
import { PopupBlockedModalComponent } from "../modules/shared/popup-blocked-modal/popup-blocked-modal.component";

export const getUrlParamValue = (href: string, paramName: string): string | undefined => {
  if (!href) {
    return undefined;
  }
  const paramIndex = href.indexOf(`${paramName}=`);
  if (paramIndex === -1) {
    return undefined;
  }
  const indexOfTrailParam = href.indexOf('&', paramIndex);
  const paramLength = paramName.length + 1; // eg. "foo="
  return decodeURIComponent(href.substring(paramIndex + paramLength, indexOfTrailParam === -1 ? undefined : indexOfTrailParam));
};

export const setQueryStringParam = (queryString: string, paramName: string, paramValue: string): string => {
  const searchParams = new URLSearchParams(queryString);
  searchParams.set(paramName, paramValue);
  return searchParams.toString();
};

export const openPotentiallyBlockedWindow = async (url: string, modalService: ModalService<any>, target: "_blank" | "_top" = "_blank") => {
  const opened = window.open(url);

  if (!opened || opened.closed || typeof opened.closed === "undefined") {
    // popup blocked (first time on Safari/Opera/Chrome), so have the user open it by clicking a link, otherwise the 'opener' is null so the opened window can't be closed programmatically
    const modal: ModalService<PopupBlockedModalComponent> = modalService.create(PopupBlockedModalComponent);
    modal.component.href = url;
    modal.component.target = target;
    await modal.open();
  }
};
