import { Component, HostListener, ViewChild } from "@angular/core";
import { ModalComponent } from "./modal.component";

@Component({
  template: ''
})
export abstract class AbstractModalComponent {

  @ViewChild('modal') modal: ModalComponent<AbstractModalComponent>;

  // when hitting escape, we hide the modal
  @HostListener('document:keydown', ['$event']) onKeyDown(e) {
    const code = e.keyCode || e.which;
    if (code === 27) {
      this.close();
    }
  }

  close(result?: any): void {
    this.modal?.close(result);
  }

  closeDelayed(result?: any): void {
    setTimeout(() => {
      this.close(result);
    }, 300);
  }

}
