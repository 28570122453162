import { Component, output, Input } from "@angular/core";

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html'})

export class NewsletterComponent {
  @Input()
  newsletter: boolean;

  newsletterChange = output<boolean>();

  toggle() {
    this.newsletter = !this.newsletter;
    this.newsletterChange.emit(this.newsletter);
  }
}
