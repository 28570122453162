@if (showLabel) {
  <label
      for="inputEmail"
      [ngClass]="order ? 'order-form-label' : (label && standalone ? '' : 'form-label')"
      [translate]="label ? label : 'PLACEHOLDER_EMAIL'"></label>
}

<input
    class="border"
    [ngClass]="order ? 'order-form-input' : 'form-input'"
    [class.border-combidesk-red]="required && email !== undefined && !isValidEmail()"
    [class.border-combidesk-green]="required && email !== undefined && isValidEmail()"
    id="inputEmail"
    name="inputEmail"
    type="email"
    [(ngModel)]="email"
    (input)="setEmail()"
    (focusout)="validateEmail($event)"
    [attr.placeholder]="'PLACEHOLDER_EMAIL' | translate"/>
