import { Injectable } from "@angular/core";
import { BaseHttpService } from "../base-http.service";
import { UserService } from "../user/user.service";
import { HttpClient } from "@angular/common/http";
import { MessageService } from "../../modules/shared/message/message.service";
import { ProgressBarService } from "../../modules/shared/progress-bar/progress-bar.service";
import { TranslateService } from "@ngx-translate/core";
import { Reviews } from "src/app/model/reviews";

@Injectable({
  providedIn: 'root'
})
export class ReviewsService extends BaseHttpService {

  constructor(protected http: HttpClient,
              protected userService: UserService,
              protected translateService: TranslateService,
              protected progressBarService: ProgressBarService,
              protected messageService: MessageService) {
    super(http, userService, translateService, progressBarService, messageService);
  }

  async getReviews(): Promise<Reviews> {
    return (await this.get<Reviews>("unauthenticated/reviews")).result;
  }
}
