import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
import { environment } from "../../environments/environment";
import { CombiOrder, CombiPackage, UserCombi, UserCombiPackage } from "../model";

export const setAnalyticsUserId = (userId: string): void => {
  if (environment.env === "prod") {
    setUserId(getAnalytics(), userId);
  } else {
    console.log("setAnalyticsUserId (not sent to Firebase): " + userId);
  }
};

export const logAnalyticsEvent = (name: string, parameters?: { [key: string]: any }): void => {
  parameters = parameters || {};
  if (environment.env === "prod") {
    logEvent(getAnalytics(), name, parameters);
  } else {
    console.log("logAnalyticsEvent (not sent to Firebase): " + name + ", parameters: " + JSON.stringify(parameters));
  }
};

export const logLogin = (method: string): void => {
  logAnalyticsEvent("login", {method});
};

export const logSignUp = (method: string): void => {
  logAnalyticsEvent("sign_up", {method});
};

export const logAddToCart = (value: number, step: string, stepValue: string): void => {
  logAnalyticsEvent("add_to_cart", {currency: "EUR", value, items: [{item_id: step, item_name: stepValue}]});
};

// export const logSearch = (searchTerm: string): void => {
//   logAnalyticsEvent("search", {search_term: searchTerm});
// };

export const logViewApiList = (item_list_id: string, item_list_name: string): void => {
  logAnalyticsEvent("view_item_list", {item_list_id, item_list_name});
};

// export const logSelectApiItem = (item_list_id: string, item_list_name: string, api: API): void => {
//   logAnalyticsEvent("select_item", {item_list_id, item_list_name, items: [convertApiToItem(api)]});
// };

export const logSelectCombiPackageItem = (item_list_id: string, item_list_name: string, combiPackage: CombiPackage): void => {
  logAnalyticsEvent("select_item", {item_list_id, item_list_name, items: [convertCombiPackageToItem(combiPackage)]});
};

export const logBeginCheckout = (value: number, userCombiPackage:  UserCombiPackage): void => {
  logAnalyticsEvent("begin_checkout", {currency: "EUR", value, items: [convertUserCombiPackageToItem(userCombiPackage)]});
};

export const logSelectRecommendedPackage = (packageInternalKey: string): void => {
  logAnalyticsEvent("select_recommended", {package: packageInternalKey});
};

export const logAddPaymentInfo = (value: number, paymentType: string, userCombiPackage:  UserCombiPackage): void => {
  logAnalyticsEvent("add_payment_info", {currency: "EUR", value, payment_type: paymentType, items: [convertUserCombiPackageToItem(userCombiPackage)]});
};

export const changePaymentMethod = (paymentType: string, combiOrder: CombiOrder): void => {
  logAnalyticsEvent("change_payment_method", {payment_type: paymentType, items: [convertCombiOrderToItem(combiOrder)]});
};

export const logPurchase = (value: number, userCombiPackage:  UserCombiPackage): void => {
  logAnalyticsEvent("purchase", {currency: "EUR", value, items: [convertUserCombiPackageToItem(userCombiPackage)]});
};

export const logRunNow = (userCombi: UserCombi): void => {
  const item = convertUserCombiToItem(userCombi);
  logAnalyticsEvent("run_now", {items: [item]});
};

export const logRunItem = (userCombi: UserCombi, fromApiItemID: string): void => {
  const item = convertUserCombiToItem(userCombi);
  item.item_variant = fromApiItemID;
  logAnalyticsEvent("run_item", {items: [item]});
};

export const logRunItemFromDashboard = (item: Item, fromApiItemID: string): void => {
  item.item_variant = fromApiItemID;
  logAnalyticsEvent("run_item_dashboard", {items: [item]});
};

export const logConsiderServiceLevelRecommendation = (userCombiPackage: UserCombiPackage, recommendedServiceLevel: string): void => {
  logAnalyticsEvent("consider_service_level_recommendation", {service_level: recommendedServiceLevel.toLowerCase(), items: [convertUserCombiPackageToItem(userCombiPackage)]});
};

const convertCombiPackageToItem = (combiPackage: CombiPackage): Item => (
    {
      item_id: combiPackage.internalKey,
      item_name: combiPackage.name
    }
);

const convertUserCombiPackageToItem = (userCombiPackage: UserCombiPackage): Item => (
    {
      item_id: userCombiPackage.packageinternalkey,
      item_name: userCombiPackage.packagename
    }
);

const convertCombiOrderToItem = (combiOrder: CombiOrder): Item => (
    {
      item_id: "" + combiOrder.id,
      item_name: combiOrder.fromapinameNormalized + " - " + combiOrder.toapinameNormalized
    }
);

const convertUserCombiToItem = (userCombi: UserCombi): Item => (
    {
      item_id: `${userCombi.fromAPIAction.apiAction.internalKey}-${userCombi.toAPIAction.apiAction.internalKey}`,
      item_name: `'${userCombi.fromAPIAction.apiAction.name}' to '${userCombi.toAPIAction.apiAction.name}'`
    }
);

export interface Item {
  item_id: string;
  item_name: string;
  item_variant?: string;
}
