import { Component, Input } from '@angular/core';
import { Star } from './Star';

@Component({
  selector: 'Stars',
  standalone: true,
  imports: [Star],
  template: `
    <div class="flex gap-0.5">
      @for (star of getFullStars(); track star) {
        <Star></Star>
      }
      @if (rating % 2 > 0) {
        <Star [half]="true"></Star>
      }
      @for (star of getEmptyStars(); track star) {
        <Star [empty]="true"></Star>
      }
    </div>
  `,
})
export class Stars {
  @Input() rating = 10;

  getEmptyStars() {
    return [...Array(Math.floor((10 - this.rating) / 2)).keys()];
  }

  getFullStars() {
    return [...Array(Math.floor(this.rating / 2)).keys()];
  }
}
