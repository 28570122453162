import { Router } from "@angular/router";
import { LocalStorageKey } from "../../../local-storage/local-storage-keys";
import { OAuthService } from "../../../services/oauth/oauth.service";
import { LocalStorageService } from "../../../local-storage/local-storage.service";
import { CombiPackageService } from "../../../services/combipackage/combipackage.service";
import { CombiPackage, OAuth, User, UserAPI } from "../../../model";
import { ModalService } from "../../shared/modal/modal.service";
import { openPotentiallyBlockedWindow } from "../../../util/url.util";

export const OAUTHRESULT_IDENTIFIER = "oauthresult=true";
export const OAUTHERROR_QUERYPARAM_NAME = "oautherror";
export const NEWUSERAPI_QUERYPARAM_NAME = "newUserAPI";

export const doOAuth = async (user: User,
                              userApi: UserAPI,
                              combiPackageService: CombiPackageService,
                              oAuthService: OAuthService,
                              localStorageService: LocalStorageService,
                              modalService: ModalService<any>,
                              router: Router,
                              additionalScopes?: string,
                              approverUserID?: string): Promise<void> => {

  // add <OAUTHRESULT_IDENTIFIER> to the url, so we know we're coming from oauth result
  let urlWithIdentifier = router.url;
  if (urlWithIdentifier.indexOf(OAUTHRESULT_IDENTIFIER) === -1) {
    urlWithIdentifier += urlWithIdentifier.indexOf("?") === -1 ? "?" : "&";
    urlWithIdentifier += OAUTHRESULT_IDENTIFIER;
  }

  // remove <NEWUSERAPI_QUERYPARAM_NAME> from urlWithIdentifier, so we don't add it more than once
  const newUserApiIndex = urlWithIdentifier.indexOf(`&${NEWUSERAPI_QUERYPARAM_NAME}`);
  if (newUserApiIndex > -1) {
    urlWithIdentifier = urlWithIdentifier.substring(0, newUserApiIndex);
  }

  localStorageService.set(LocalStorageKey.REDIRECTTO_AFTER_OAUTHRESULT, urlWithIdentifier);

  const combiPackage: CombiPackage = localStorageService.get(LocalStorageKey.CP_COMBIPACKAGE);

  const oAuthRequest: OAuth = {
    apiid: userApi.api.id,
    name: userApi.name,
    url: userApi.url,
    packageName: combiPackage?.internalKey,
    userApiID: userApi.id,
    consumerKey: userApi.publicKey,
    consumerSecret: userApi.privateKey,
    redirectTo: urlWithIdentifier,
    additionalScopes,
    newFrontend: true,
    approverUserID
  };

  const oAuthRequestResult = await oAuthService.request(oAuthRequest);
  if (oAuthRequestResult) {
    localStorageService.set(LocalStorageKey.OAUTHRESULT, oAuthRequestResult);

    // using a stripped user object, otherwise the URL/querystring may become too long ("Request-URI Too Large")
    const userDTOStripped = {
      encryptedID: user.encryptedID,
      email: user.email,
      password: user.password,
      language: user.language,
      temporaryUser: user.temporaryUser
    };

    const cPackage = combiPackageService.getCombiPackageFromService();
    if (cPackage && cPackage.openOAuthInNewWindow) {
      localStorageService.set(LocalStorageKey.REDIRECTTO_AFTER_OAUTHRESULT, "close");

      const url = "/lsredirectproxy.html?url=" + encodeURIComponent(oAuthRequestResult.authUrl) + "&data=" +
          encodeURIComponent(
              LocalStorageKey.OAUTHRESULT + ";;" + JSON.stringify(oAuthRequestResult) + "&&" +
              LocalStorageKey.REDIRECTTO_AFTER_OAUTHRESULT + ";;close" + "&&" +
              LocalStorageKey.USER + ";;" + JSON.stringify(userDTOStripped)
          );

      await openPotentiallyBlockedWindow(url, modalService);
    } else if (cPackage && cPackage.internalKey.startsWith("bizcuit-") && /^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      const url = "/lsredirectproxy.html?url=" + encodeURIComponent(oAuthRequestResult.authUrl) + "&data=" +
          encodeURIComponent(
              LocalStorageKey.OAUTHRESULT + ";;" + JSON.stringify(oAuthRequestResult) + "&&" +
              LocalStorageKey.REDIRECTTO_AFTER_OAUTHRESULT + ";;" + encodeURIComponent(urlWithIdentifier) + "&&" +
              LocalStorageKey.USER + ";;" + JSON.stringify(userDTOStripped)
          );
      window.top.location = url;
    } else {
      // note: don't add random querystring parameters to the URL, as it may break the OAuth flow (registered redirect_uri)
      if (oAuthRequestResult.authUrl.indexOf("https") > -1) {
        window.top.location = oAuthRequestResult.authUrl;
      } else {
        window.location.href = oAuthRequestResult.authUrl;
      }
    }
  }
};
