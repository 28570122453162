import { HttpClient, HttpParameterCodec, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { sleep } from "../util/sleep.util";
import { UserService } from "./user/user.service";
import { MessageService } from "../modules/shared/message/message.service";
import { ProgressBarService } from "../modules/shared/progress-bar/progress-bar.service";
import { TranslateService } from "@ngx-translate/core";
import { firstValueFrom } from "rxjs";

// see ErrorButtonAction.java
export type ButtonAction = "LOGIN_TO_USER_API" | "CLOSE";

export interface ErrorObjectLine {
  title: string;
  body: string;
}

export interface ErrorObject {
  title: string;
  body: string;
  buttonText: string;
  buttonAction: ButtonAction;
  errorLines: Array<ErrorObjectLine>;
}

export interface APIResult<T> {
  success: boolean;
  result: T;
  messages?: {
    errors?: Array<string>;
    warnings?: Array<string>;
    successes?: Array<string>;
    errorObject?: ErrorObject;
  };
}

const ENDPOINT = environment.backendEndpoint;

class MyHttpUrlEncodingCodec implements HttpParameterCodec {
  encodeKey(k: string): string {
    return encodeURIComponent(k);
  }

  encodeValue(v: string): string {
    return encodeURIComponent(v);
  }

  decodeKey(k: string): string {
    return decodeURIComponent(k);
  }

  decodeValue(v: string) {
    return decodeURIComponent(v);
  }
}

export class BaseHttpService {

  private static REQUESTS_IN_PROGRESS = 0;

  private intervalRef: any;
  private expectedPromise: Promise<APIResult<any>>;

  protected constructor(protected http: HttpClient,
                        protected userService: UserService,
                        protected translateService: TranslateService,
                        protected progressBarService: ProgressBarService,
                        protected messageService: MessageService) {
  }

  public snoozeMessages<T>(): T {
    this.messageService.snooze();
    return <T><unknown>this;
  }

  protected async get<T>(uri: string, queryParams?: { [prop: string]: any }): Promise<APIResult<T>> {
    this.startProgress();
    try {
      const params = this.getParams(queryParams);
      const promise = firstValueFrom(this.http.get<APIResult<T>>(`${ENDPOINT}/${uri}`, {params}));
      this.setExpectedPromise(promise);
      return this.handleResponse(await promise);
    } catch (err) {
      if (err.status === 0) {
        // backend not available
        this.messageService.showErrorMessage("We're currently doing maintenance. Please try again in a minute!");
      } else {
        this.messageService.showErrorMessage(err.error ? err.error : err.message ? err.message : err);
      }
      await this.resetProgressBar();
      return undefined;
    }
  }

  protected async post<T>(uri: string, payload?: { [prop: string]: any }): Promise<APIResult<T>> {
    this.startProgress();
    try {
      const params = this.getParams(payload);
      const promise = firstValueFrom(this.http.post<APIResult<T>>(`${ENDPOINT}/${uri}`, params, {reportProgress: true}));
      this.setExpectedPromise(promise);
      return this.handleResponse(await promise);
    } catch (err) {
      if (err.status === 0) {
        // backend not available
        this.messageService.showErrorMessage("We're currently doing maintenance. Please try again in a minute!");
      } else {
        this.messageService.showErrorMessage(err.error ? err.error : err.message ? err.message : err);
      }
      await this.resetProgressBar();
      return undefined;
    }
  }

  private startProgress(): void {
    BaseHttpService.REQUESTS_IN_PROGRESS++;
    if (this.intervalRef) {
      return;
    }
    this.progressBarService.percentageLoaded = 5;
    this.intervalRef = setInterval(() => {
      this.updateProgress();
    }, 500);
  }

  private updateProgress(): void {
    const current = this.progressBarService.percentageLoaded;
    const tenPercent = (100 - current) / 10;
    this.progressBarService.percentageLoaded += tenPercent;
  }

  private getParams(paramsObject): HttpParams {
    let params = new HttpParams({encoder: new MyHttpUrlEncodingCodec()});
    let user;
    if (paramsObject) {
      for (const [k, v] of Object.entries(paramsObject)) {
        if (v) {
          params = params.append(k, typeof (v) === "string" ? v : JSON.stringify(v));
        }
      }
      user = paramsObject.user;
    }

    if (!user) {
      user = this instanceof UserService ? this.getUser() : this.userService?.getUser();
      // always pass the user to the backend for authentication purposes
      if (user) {
        params = params.append("user", JSON.stringify(user));
      }
    }

    const language = user?.language || this.translateService.currentLang;

    // always pass the user's language, so messages are in the same language as the frontend
    return params.append("language", language);
  }

  private handleResponse<T>(responseData: APIResult<T>): APIResult<T> {
    this.resetProgressBar();
    if (responseData?.messages) {
      if (responseData.messages.errorObject) {
        this.messageService.showErrorModal(responseData.messages.errorObject);
      } else {
        // note that we only show the last message because there can be only one active message ;)
        responseData.messages.successes.forEach(m => this.messageService.showSuccessMessage(m));
        if (!this.messageService.hasActiveStickyMessage()) { // let's not overwrite older messages as the may be the cause of other messages)
          responseData.messages.warnings.forEach(m => this.messageService.showWarningMessage(m));
          responseData.messages.errors.forEach(m => this.messageService.showErrorMessage(m));
        }
      }
    }
    return responseData;
  }

  private async resetProgressBar() {
    BaseHttpService.REQUESTS_IN_PROGRESS--;
    clearInterval(this.intervalRef);
    this.intervalRef = undefined;
    this.progressBarService.percentageLoaded = 100;

    await sleep(750);

    if (BaseHttpService.REQUESTS_IN_PROGRESS < 1) {
      this.progressBarService.percentageLoaded = 0;
    }
  }

  private setExpectedPromise(expectedPromise: Promise<APIResult<any>>) {
    this.expectedPromise = expectedPromise;
    if (this.messageService.snoozed()) {
      this.expectedPromise.finally(() => setTimeout(() => this.messageService.stopSnoozing(), 1000));
    }
  }
}
