import { Component, Input } from '@angular/core';

import { Wrap } from './Wrap';

@Component({
  selector: 'ContentIllustration',
  standalone: true,
  imports: [Wrap],
  template: `
    <section class="my-section">
      <Wrap
        class="
        flex flex-col justify-center items-center w-full gap-10 md:gap-20 xl:gap-30
        {{ inverse ? 'lg:flex-row' : 'lg:flex-row-reverse' }}
        "
      >
        <img
          [alt]="illustration.alt || alt"
          src="assets/images/{{ illustration.name }}.svg"
          class="w-full h-auto max-h-[420px] {{
            large ? 'lg:w-4/10 max-w-[420px]' : 'lg:w-1/2 max-w-[480px]'
          }}"
          [height]="illustration.height"
          [width]="illustration.width"
        />

        <div
          class="{{
            large ? 'lg:w-[55%] lg:max-w-[660px]' : 'lg:w-1/2 lg:max-w-[480px]'
          }}"
        >
          <ng-content></ng-content>
        </div>
      </Wrap>
    </section>
  `,
})
export class ContentIllustration {
  @Input() illustration: {
    name: string;
    alt?: string;
    height: number;
    width: number;
  } = {
    name: "intro",
    height: 435,
    width: 420,
  };
  @Input() alt = "";
  @Input() inverse = false;
  @Input() large = false;
}
