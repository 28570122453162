import { Component, Input } from '@angular/core';

@Component({
  selector: 'Arrow',
  standalone: true,
  template: `
    <svg
      [attr.height]="height"
      [attr.width]="width"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd">
        <path d="M0 6h10M5 1l5 5-5 5" />
      </g>
    </svg>
  `,
})
export class Arrow {
  @Input() height = '12';
  @Input() width = '12';
}
