import { Component, Input } from '@angular/core';

import { Button } from './Button';
import { Wrap } from './Wrap';

@Component({
  selector: 'ContentImage',
  standalone: true,
  imports: [Button, Wrap],
  template: `
    <section class="my-section">
      <Wrap [class]="'lg:flex items-center'">
        <div
          class="
            pl-5
            pb-5
            mb-8
            sm:mb-12
            max-w-[560px]
            sm:pl-15
            sm:pb-15
            sm:max-w-[600px]
            lg:order-1
            lg:mb-0
            lg:ml-15
            lg:w-1/2
            lg:flex-shrink-0
          "
        >
          <div class="relative flex">
            <img
              alt="{{ imageAlt }}"
              class="rounded-3xl sm:rounded-4xl z-10"
              height="360"
              src="assets/images/{{ img }}.jpg"
              srcset="assets/images/{{ img }}@2x.jpg 2x"
              width="540"
            />
            <div
              class="
                absolute
                bg-gradient-to-br
                from-blue-50
                to-blue-200
                rounded-3xl
                sm:rounded-4xl
                w-full
                h-full
                top-0
                left-0
                -ml-5
                mt-5
                sm:-ml-15
                sm:mt-15
              "
            ></div>
          </div>
        </div>
        <div>
          <ng-content></ng-content>
        </div>
      </Wrap>
    </section>
  `,
})
export class ContentImage {
  @Input() imageAlt = 'Combidesk';
  @Input() img = 'team';
}
