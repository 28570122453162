import { Injectable } from "@angular/core";
import { BaseHttpService } from "../base-http.service";
import { UserService } from "../user/user.service";
import { HttpClient } from "@angular/common/http";
import { OAuth, UserAPI } from "../../model";
import { MessageService } from "../../modules/shared/message/message.service";
import { ProgressBarService } from "../../modules/shared/progress-bar/progress-bar.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class OAuthService extends BaseHttpService {

  constructor(protected http: HttpClient,
              protected userService: UserService,
              protected translateService: TranslateService,
              protected progressBarService: ProgressBarService,
              protected messageService: MessageService) {
    super(http, userService, translateService, progressBarService, messageService);
  }

  async request(oAuth: OAuth): Promise<OAuth | undefined> {
    const response = await this.post<OAuth>("authenticated/oauth/request", {oAuth});
    if (response?.success) {
      return response.result;
    }
  }

  async result(oAuth: OAuth): Promise<UserAPI | undefined> {
    const response = await this.post<UserAPI>("authenticated/oauth/result", {oAuth});
    if (response && response.success) {
      return response.result;
    }
  }
}
