import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'BizcuitButton',
  standalone: true,
  imports: [CommonModule],
  template: `
    @if (href) {
      <a href="{{ href }}"
         target="{{ target }}"
         rel="{{ rel }}"
         class="{{ classes }}">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </a>
    } @else {

      @if (!submit) {
        <div class="{{ classes }}">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
      } @else {
        <button type="submit" [disabled]="disabled" style="{{ styles }}" class="{{ classes }}"
                [style.opacity]="disabled ? .4 : 1">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </button>
      }
    }

    <ng-template #submit>
      <button type="submit" [disabled]="disabled" style="{{ styles }}" class="{{ classes }}" [style.opacity]="disabled ? .4 : 1">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </button>
    </ng-template>

    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
  `,
})
export class BizcuitButton implements OnInit {
  @Input() submit = false;
  @Input() disabled = false;
  @Input() target = "_self";
  @Input() color: any;
  @Input() href = '';
  @Input() rel = 'opener';

  classes = 'my-button sm:w-[317px] flex items-center justify-center rounded-2xl font-mark-bold font-semibold text-white w-full px-3 sm:px-6 py-[11px] text-[17px] text-center';
  styles: string;

  ngOnInit() {
    this.styles = `background-color: ${this.color}`;
  }
}
