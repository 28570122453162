<section
    class="fixed z-40 inset-0 overflow-y-auto overscroll-none backdrop-grayscale backdrop-blur-sm fadeInOnEnterQuickly"
    [class.open]="display">
  <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0 bg-gray-800/40">
    <div
        (click)="$event.stopPropagation()"
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-xl md:max-w-2xl sm:w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <button (click)="close()" class="close-modal float-right text-2xl leading-none -mt-2.5 -mr-1 p-3 text-gray-800 duration-200 hover:text-black hover:scale-125">
          <span>×</span>
        </button>
        <ng-content></ng-content>
      </div>
    </div>
  </div>

</section>