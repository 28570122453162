@if (message.active) {
  <main [class.opacity-0]="message.hide" [class.opacity-100]="!message.hide"
        class="transition-opacity duration-700 fixed top-0 left-0 z-50 flex flex-col w-screen h-full m-auto">

    <section
        class="z-40 flex flex-col fixed h-full w-full mx-auto overflow-auto opacity-60 bg-gray-200 dark:bg-gray-600">
    </section>

    <section
        class="z-50 flex flex-col fixed h-full w-full mx-auto overflow-auto max-w-screen-xl md:h-8/10 md:p-2 md:shadow-2xl md:rounded-xl md:top-1/10 md:left-2/10 md:w-6/10 bg-gradient-to-b from-white to-[#FFF6ED] dark:from-black dark:to-black ">

      <div class="p-4 mt-2 flex-grow">
        <div class="h4 font-bold leading-tight" [innerHTML]="message.errorObject.title"></div>

        @if (message.errorObject.body) {
          <div class="mt-4" [innerHTML]="message.errorObject.body"></div>
        }

        @for (errorLine of message.errorObject.errorLines; track errorLine.title) {
          <InfoCard
              [titleKey]="errorLine.title"
              [bodyKey]="errorLine.body"></InfoCard>
        }
      </div>

      <button
          class="absolute bg-transparent text-4xl leading-none right-0 top-0 py-2 px-3 outline-none focus:outline-none hidden md:block"
          (click)="hideMessage()">
        <span [style.color]="appColor ? appColor : '#FF8000'">×</span>
      </button>

      @if (message.errorObject.buttonAction) {
        <footer class="flex flex-col justify-center mb-4 mx-4">
          <!--      <div class="mt-10 text-center underline cursor-pointer font-medium" (click)="selectUserAPI()" translate="PAGE_BIZCUIT_USERAPI_SELECTION_LATER"></div>-->
          <div class="flex justify-center">
            <BizcuitButton
                [color]="appColor ? appColor : '#FF8000'"
                (click)="buttonClicked()">
              {{ message.errorObject.buttonText }}
            </BizcuitButton>
          </div>
        </footer>
      }
    </section>
  </main>
}
