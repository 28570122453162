import { Component, Input } from '@angular/core';

@Component({
  selector: 'SelectBox',
  standalone: true,
  template: `
    <div class="relative flex items-center text-gray-800">
      <ng-content></ng-content>
      <svg
        class="absolute {{ alignClass }} pointer-events-none"
        fill="none"
        height="20"
        stroke-width="2.5"
        stroke="currentColor"
        viewBox="0 0 24 24"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polyline points="6 9 12 15 18 9"></polyline>
      </svg>
    </div>
  `,
})
export class SelectBox {
  @Input() alignClass = 'right-5';
}
